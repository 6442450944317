.zakelijk {
    background-size: cover;
    background-position: top;
    transition-duration: 0.4s;

}

.main-zakelijk:hover .zakelijk {
    background-position: bottom;
}

.back {
    display: none;
    position: absolute;
    background-color: #2c4e96;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    z-index: 10;
    bottom: 0;

}

.main-zakelijk:hover .back {
    background-position: bottom;
    display: block;
}

.cap{
    transition: 0.3s;
}
.main-zakelijk:hover .cap {
    transform: translateY(-18%);
}
.bg-primary {
    background-color: #00b0f0;
    border-radius:  0 0 0.75rem 0.75rem;
}
.custom-rounded-xl {
    border-radius: 0.75rem 0.75rem 0 0;
}