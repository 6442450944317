.item {
  background-color: #2b2b2b;
  padding: 4rem 0;
}
.item-dec-main {
  align-items: center;
  flex-direction: column;
}
.item-sec a{
  display: flex;
  justify-content: space-between;
  border: 2px solid transparent;
  padding: 40px 20px;
  flex-wrap: wrap;
  transition: border-color 0.3s;
  border-radius: 5px;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: 0.2s;
  /* &:hover{
    .section-two  p{
      text-decoration: underline;
      color: #161616;
    }
  } */
  background-color: #00b0f0;
}
/* .item-sec a:hover {
  border-color: white;
  cursor: pointer;
} */
.item-sec a:hover {
opacity: 0.8;
}
.section-one {
  width: 80%;
}
.section-one h2 {
  font-weight: 500;
  line-height: 1.2;
}
.section-one .flex-item {
  display: flex;
  margin: 10px 0;
}
.section-one .flex-item .flex {
  color: white;
  font-weight: 400;
  margin-right: 20px;
  display: flex;
}
.section-one .flex-item .d-flex {
  color: white;
  font-weight: 400;
  display: flex;
}
.section-two  p {
  color: white;
  font-weight: 500;

}