.page-header {
  background-image: url("https://qleanbusiness.nl/wp-content/uploads/2018/05/slideblok2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.container-contact {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}


.arrow a::after {
  content: "";

  transform: translateY(-30%);
  position: absolute;
  top: 8px;
  left: 60px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 8px solid #999;
}

/******/

.card {
  height: 330px;
  border: 1px solid #d0d0d0;
  border-top: 4px solid #eee;
  min-width: 280px;
  max-width: 280px;
}
.card:hover {
  border-top: 4px solid rgb(0,176,240);
  transition: ease-in-out 0.8s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}
.card ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15%;
}
.card ul .icon {
  color: rgb(0,176,240);
  font-size: 3em;
  padding-bottom: 20px;
}
.card ul .header {
  font-weight: 600;
  font-size: 20px;
  padding: 15px 0;
  color: #333333;
}
.card ul .footer-card {
  font-weight: 600;
  font-size: 20px;
  color: #515151;
}
 .card ul .num-one {
  color: rgb(30, 89, 147);

} 
.card.num-two {
  color: #999;
  font-size: 18px;
  font-weight: 300;
  padding-top: 12px;

  &:hover {
    text-decoration: underline;
  }
}

.map {
  padding-top: 10%;
}
/****/
.inform-mail {
  padding-top: 6%;
  width: 100%;
}
.inform-mail .border {
  padding: 25px;
  text-align: center;
  height: 240px;
  padding-top: 80px;
  margin: 0 20px;

  background: #f1f1f1;
  position: relative;
}
.inform-mail .border h2 {
  color: #333;
  font-size: 32px;
  font-weight: 900;
  line-height: 1.2;
}
.inform-mail .border ::after {
  content: "";
  border: 2px solid rgb(0,176,240);
  position: absolute;
  top: 82%;
  right: 32%;
  width: 35%;
}
.inform-mail .inform-enter {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}
.inform-mail .inform-enter input {
  border: 1px solid #e8e8e8;
  border-top: 3px solid #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 12px 10px;
  margin-bottom: 18px;
  font-size: 15px;
  font-weight: 300;
  width: 100%;
  line-height: 1.67;
  color: #777;
  background-color: #fff;
}
.inform-mail .inform-enter input:focus {
  color: #2f2f2f;
  border: 1px solid #e8e8e8;
  border-top: 3px solid rgb(0,176,240);
}
.inform-mail .message {
  margin: 0 20px;
}
.inform-mail .message textarea {
  height: 13.33333rem;
  margin-bottom: 0;
  padding: 0.91667rem 1.11111rem;
  font-size: 0.77778rem;
  border: 1px solid #e8e8e8;
  border-top: 3px solid #ddd;
  border-bottom-left-radius: 0.11111rem;
  border-bottom-right-radius: 0.11111rem;
  line-height: 1.67;
  color: #777;
  background-color: #fff;
}
.inform-mail .message textarea:focus {
  color: #999;
  /*   border: 1px solid #e8e8e8;
 */
  border-top: 3px solid rgb(0,176,240);
}
.inform-mail .message .photo-upload {
  cursor: pointer;
  margin: 5% 8px;
}

.btn {
  background-color: rgb(0,176,240);
  color: #fff;
  padding: 10px 30px;
  font-size: 15px;
  font-weight: 500;
  margin: 20px 0 5rem 0;
  cursor: pointer;
}

.btn:hover {
  background-color: #777;
}
.copy-icon {
  color: rgb(0,176,240);
}
.ruby-text {
  display: ruby-text;
}
