body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  /* font-family: ""Helvetica Neue",Helvetica,Arial,sans-serif"; */
}

.title-back {
  /* background-image: url("../../components/assests/blue.jpg");
  background-repeat: no-repeat; */
  background: rgb(0, 51, 70);
  background: linear-gradient(90deg, rgba(0, 51, 70, 1) 0%, rgba(0, 109, 149, 1) 23%, rgba(0, 176, 240, 1) 100%);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
}

.title-back h1 {
  color: #fff;
  width: 100%;
  padding: 0px 80px;
}

.bottom-title {
  padding: 3px 25%;
  width: 50%;
  position: relative;
}