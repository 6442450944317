.slide-container {
    width: 80%;
    margin: auto;
}

.each-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 200px; */
    /* background: #f5f5f5; */
    border-radius: 10px;
    border: 1px solid #80808042;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0 5px;
}

.slide-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.slide-content span {
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
}