.desc-page {
  background-color: #00b0f0;
}
.desc-page .head-desc {
  padding: 5% 30px;
}

.desc-page .head-desc button a {
  color: #eee;
  font-size: 15px;
  font-weight: 400;
}
.desc-page .head-desc button a:hover {
  text-decoration: underline;
  transition: ease-in-out 0.5s;
}
.desc-page .head-desc h2 {
  font-size: 35px;
  color: #eee;
  font-weight: bold;
  margin: 20px 0;
}
.desc-page .head-desc p {
  margin-bottom: 1%;
}
.desc-page .head-desc p span {
  color: #eee;
  font-size: 15px;
  font-weight: 300;
  margin: 0 25px;
  margin-left: 0;
}
.main-desc h2 {
  font-size: 20px;
  color: #000;
  font-weight: 500;
}
.main-desc p {
  font-size: 15px;
  color: #555;
}
.main-desc ul li {
  font-size: 15px;
  color: #555;
  
}
.btn-vac {
  background-color: rgb(0, 176, 240);
  color: #fff;
  padding: 10px 30px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}