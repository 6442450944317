.slidmain {
  background-size: cover;
  background-position: center;
  background-color: rgb(0, 0, 0, 0);
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 85vh;
}

.container {
  position: relative;
}

.slider {
  display: flex;
  gap: 10px;
}

.slide {
  transition: transform 0.3s ease;
  max-height: 85vh;
}

.preview {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  overflow: hidden;
}
.home-text-shadow {
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5); /* Adds a shadow to the text */
}

.preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes toLeft {
  0% {
    transform: translateX(calc(1.875rem+ 1.875rem - 100%));
  }

  25% {
    transform: translateX(calc(1.875rem+ 1.875rem - 110%));
  }

  50% {
    transform: translateX(calc(1.875rem+ 1.875rem - 113%));
  }

  100% {
    transform: translateX(calc(1.875rem + 1.875rem - 47%));
    flex-direction: row-reverse;
  }
}

.arrimg {
  max-width: unset;
}

.arrbtn1 {
  transform: translateX(calc(1.875rem - 100%));
  background: #8080806b;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  /* animation-timing-function: linear ; */
  animation-timing-function: cubic-bezier(0.69, -0.37, 0.95, 1.07);
  transition: 1s;
}
.arrbtn1:hover {
  animation-name: toLeft;
}

/* 0% {transform: translateX(calc(1.875rem+ 1.875rem - 100%));}

25% {transform: translateX(calc(1.875rem+ 1.875rem - 110%));}

50% { transform: translateX(calc(1.875rem+ 1.875rem - 113%));} */

@keyframes toRight {
  0% {
    transform: translateX(calc(100% - 1.875rem));
  }

  25% {
    transform: translateX(calc(95% - 1.875rem));
  }

  50% {
    transform: translateX(calc(90% - 1.875rem - 1.875rem));
  }

  100% {
    transform: translateX(calc(47% - 1.875rem - 1.875rem));
    flex-direction: row-reverse;
  }
}

.arrimg {
  max-width: unset;
}

.arrbtn2 {
  transform: translateX(calc(100% - 1.875rem));
  background: #8080806b;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  /* animation-timing-function: cubic-bezier(0.69, -0.37, 0.95, 1.07) ; */
  transition: 1s;
  position: absolute;
}

.arrbtn2:hover {
  animation-name: toRight;
}

#overlay {
  position: absolute;
  width: 100%;
  height: 85vh;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(13, 20, 48);
  background: linear-gradient(
    90deg,
    rgba(13, 20, 48, 0.53687412464986) 32%,
    rgba(0, 0, 0, 0.13071165966386555) 70%,
    rgba(0, 212, 255, 0) 100%
  );
  z-index: 2;
}
.home-text {
  top: 45%;
}

@media (max-width: 768px) {
  #overlay {
    height: 55vh;
  }
  .home-text {
    top: 20%;
  }
  .slidmain {
    height: 55vh;
  }
  .slide {
    height: 55vh;
  }
}
@media (max-width: 500px) {
  #overlay {
    height: 40vh;
  }
  .home-text {
    top: 15%;
  }
  .slidmain {
    height: 40vh;
  }
  .slide {
    height: 40vh;
  }
}
