@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden;
}
.react-slideshow-container{
  overflow: hidden;
}
.primary-color {
  color: rgb(0,176,240);
}
.secondary-color {
  color: #9ee5ff;
}