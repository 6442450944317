.image-details-border {
  border-radius: 1.5rem 0 0 1.5rem;
}
.text-details-border {
  border-radius: 0 1.5rem 1.5rem 0;
}
.primary-bg-color {
  background-color: rgb(0, 176, 240);
}
.border-primary {
  border-color: rgb(0, 176, 240);
}
@media (max-width: 768px) {
  .text-details-border {
    border-radius: 0 0 0.75rem 0.75rem;
  }
  .image-details-border {
    border-radius: 0.75rem 0.75rem 0 0;
  }
}